import Vue from "vue";
import VueRollbar from "vue-rollbar";

const {
  VUE_APP_ROLLBAR_TOKEN,
  VUE_APP_ROLLBAR_ENVIRONMENT,
  NODE_ENV
} = process.env;

if (NODE_ENV === "production") {
  Vue.use(VueRollbar, {
    accessToken: VUE_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true,
    source_map_enabled: true,
    environment: VUE_APP_ROLLBAR_ENVIRONMENT
  });

  Vue.config.errorHandler = function(err, _vm, _info) {
    Vue.rollbar.error(err);
  };
}
