import Vue from "vue";
import router from "@/router";
import store from "@/store";
import { createProvider } from "@/vue-apollo";
import App from "@/App.vue";
import "@/assets/css/app.scss";
import "./assets/tailwind.css";
import i18n from "@/plugins/i18n";
import auth from "@/plugins/auth.js";
import "@/plugins/rollbar.js";
import "@/plugins/gTag.js";
import "@/plugins/fontAwesome.js";
import "@/plugins/buefy.js";
import "@/plugins/veeValidate.js";

const { NODE_ENV } = process.env;

// Redirect if no token provided
const host = window.location.host.replace("activation", "app");
if (NODE_ENV === "production" && !auth.token)
  window.location.replace(`https://${host}/activation`);

Vue.config.productionTip = false;

export default new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  i18n,
  render: h => h(App)
}).$mount("#app");
